import React, { useRef } from 'react';
import { Link } from 'react-router-dom'; // Import Link for navigation
import './LandingPage.css'; // Import your CSS file
import howItWorksImage from './HowItWorks2.png';
import FeaturesPage from './FeaturesPage.mjs';
import PricingPage from './PricingPage.js';
import PreOrdersPage from './PreOrders.js';

function LandingPage() {
  const sections = useRef([]); // Ref to hold section references for scrolling

  const scrollToSection = (index) => {
    sections.current[index].scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <div>
      <header>
        <div class="nav-container">
        <nav >
        <ul className="left-links"> {/* NEW: For left-aligned links */}
            <li><Link to="/blog">Blog</Link></li>
          </ul>
          <ul class="centered-links">
          <li><a href="#how-it-works" onClick={(e) => { e.preventDefault(); scrollToSection(1); }}>How It Works</a></li>
          <li><a href="#features" onClick={(e) => { e.preventDefault(); scrollToSection(2); }}>Features</a></li>
            <li><a href="#pricing" onClick={(e) => { e.preventDefault(); scrollToSection(3); }}>Pricing</a></li>
            <li><a href="#getting-started" onClick={(e) => { e.preventDefault(); scrollToSection(4); }}>Getting Started</a></li>
            </ul>
            <ul class="right-links">
            <li><Link to="/login">Login</Link></li> {/* Use Link for navigation */}
            <li><Link to="/account">Account</Link></li>
          </ul>
        </nav>
        </div>
      </header>

    <div className="landing-container">

    <section ref={(el) => (sections.current[0] = el)}>
      <div style={{marginTop: "-80px", height: "400px", textAlign: "center"}}>
        <h1 className="flashy-title">Origin Security</h1>
          <h1>Accelerate Your Web Performance</h1>
          <p>The next generation reverse-proxy solution for a secure, more reliable web experience.</p>
          <br></br>
          <Link to="/signup" className="cta-button">Get Started In Minutes</Link> {/* Signup call-to-action */}
          </div>
          <div></div>
      </section>

      <section id="how-it-works" className="how-it-works" ref={(el) => (sections.current[1] = el)}>
        <div style={{marginTop: "-300px", textAlign: "center"}}>
          <div style={{  display: "flex", justifyContent: "center", alignItems: "center"}} >
          <img style={{width: "667px", height: "285px" }} src={howItWorksImage} alt="How it works" />
          </div>
          </div>
      </section>

      <section id="features" ref={(el) => (sections.current[2] = el)}>
        <FeaturesPage style={{marginTop:"-30px"}}/>
      </section>

      <section id="pricing" ref={(el) => (sections.current[3] = el)}>
      <div style={{
      backgroundColor: '#F5F5F1',
      color: '#E84C4E',
      padding: '10px',
      marginBottom: '15px',
      fontSize: '20px',
      display: 'flex',
      justifyContent: 'center',
      textAlign: 'center',
      marginLeft: '50px',
      marginRight: '50px'
    }}>
      Important!: Origin Security is in Beta and still under active development. Bot and DDoS mitigation are not yet active. Please read the terms of service to check the beta limitations.
    </div>
        <PricingPage style={{marginTop:"-80px"}}/>
      </section>

      <section id="getting-started" className="getting-started" ref={(el) => (sections.current[4] = el)}>
        <div className="container">
          <h1>How to Get Started</h1>
          <div>
            <p style={{textIndent: "20px", textAlign:"center", justifyContent: "center"}}>1. Set up your account and input the IP address of your origin server</p>
            <p style={{textIndent: "20px", textAlign:"center", justifyContent: "center"}}>2. Purchase credits</p>
            <p style={{textIndent: "20px", textAlign:"center", justifyContent: "center"}}>3. Update the A Record with your DNS provider to Origin IP: 3.210.119.188</p>
          <p style={{textIndent: "20px", textAlign:"center", justifyContent: "center"}}>Your traffic will start routing through our servers after about 5 minutes.</p>
          </div>
          </div>
      </section>
      
      <section id="preorders">
        <PreOrdersPage style={{marginTop:"-30px"}}/>
      </section>
    </div>
    </div>
  );
}

export default LandingPage;