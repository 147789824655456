import React, { useEffect, useRef } from "react";
import * as d3 from "d3";

function TreeDiagram({ data, targetId }) {
  const chartRef = useRef(null);
  const randomJitter = 5; // Amount of random horizontal displacement
  const levelsToAlternate = 2; // Number of levels for which to apply alternating visibility

  useEffect(() => {
    const targetElement = document.getElementById(targetId);

    if (data && targetElement) {
      targetElement.innerHTML = "";

      const margin = { top: 50, right: 90, bottom: 30, left: 90 },
        width = 1248 - margin.left - margin.right,
        height = 1560 - margin.top - margin.bottom;

      const svg = d3
        .select(targetElement)
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", "translate(" + margin.left + "," + margin.top + ")");

      // Vertical tree layout
      const treemap = d3.tree().size([width, height]);

      let root = d3.hierarchy(data, function (d) {
        return d.children;
      });
      root.x0 = width / 2;
      root.y0 = 0;

      // Expand all nodes initially
      function expand(d) {
        if (d._children) {
          d.children = d._children;
          d._children = null;
        }
        if (d.children) {
          d.children.forEach(expand);
        }
      }

      // Collapse odd-indexed level-1 nodes
      if (root.children) {
        root.children.forEach((child, i) => {
          if (i % 2 !== 0) {
            // Collapse odd-indexed level-1 nodes
            if (child.children) {
              child._children = child.children;
              child.children = null;
            }
          } else {
            // Ensure even-indexed level-1 nodes are expanded
            expand(child);
          }
        });
      }

      update(root);

      function update(source) {
        const treeData = treemap(root);
        const nodes = treeData.descendants(),
          links = treeData.descendants().slice(1);

        // Maintain a level-based mapping of nodes
        const levelMap = {};

        // Normalize for fixed-depth
        nodes.forEach(function (d, i) {
          d.y = d.depth * 100;

          if (!levelMap[d.depth]) {
            levelMap[d.depth] = {
              nodes: [],
              visibleIndex: 0, // Track the index of the next visible node at this level
            };
          }
          levelMap[d.depth].nodes.push(d);

          // Apply random jitter
          d.x += (Math.random() - 0.5) * randomJitter;
        });

        let i = 0;
        const node = svg
          .selectAll("g.node")
          .data(nodes, function (d) {
            return d.id || (d.id = ++i);
          });

        const nodeEnter = node
          .enter()
          .append("g")
          .attr("class", "node")
          .attr("transform", function (d) {
            return "translate(" + source.x0 + "," + source.y0 + ")";
          })
          .on("click", click);

        nodeEnter
          .append("circle")
          .attr("class", "node")
          .attr("r", 1e-6)
          .style("fill", function (d) {
            return d._children ? "lightsteelblue" : "#fff";
          });

        nodeEnter
          .append("text")
          .attr("dy", ".35em")
          .attr("class", "node-text")
          .attr("text-anchor", "start") // Default to start (will be adjusted)
          .text(function (d) {
            return d.data.name;
          })
          .style("opacity", 0) // Initially hide text
          .each(function (d) {
            const bbox = this.getBBox();
            d.textBBox = bbox; // Store the bounding box for later use
          })
          .on("mouseover", handleMouseOver)
          .on("mouseout", handleMouseOut);

        const nodeUpdate = nodeEnter.merge(node);

        nodeUpdate
          .transition()
          .duration(750)
          .attr("transform", function (d) {
            return "translate(" + d.x + "," + d.y + ")";
          });

        nodeUpdate
          .select("circle.node")
          .attr("r", 5)
          .style("fill", function (d) {
            return d._children ? "lightsteelblue" : "#fff";
          })
          .attr("cursor", "pointer");

        // Position text to the left or right based on level and alternating pattern
        nodeUpdate.selectAll("text.node-text").each(function (d) {
          const text = d3.select(this);
          const isExpanded = d.children;

          if (isExpanded) {
            const levelData = levelMap[d.depth];
            const hasLeftSibling =
              d.parent && d.parent.children.indexOf(d) > 0;

            if (d.depth < levelsToAlternate) {
              // Alternate text position for the first few levels
              if (hasLeftSibling) {
                text.attr("x", -10).attr("text-anchor", "end"); // Position to the left
              } else {
                text.attr("x", 10).attr("text-anchor", "start"); // Position to the right
              }
            } else {
              // Default to positioning on the right for deeper levels
              text.attr("x", 10).attr("text-anchor", "start");
            }

            text.style("opacity", 1); // Show text
          } else {
            text.style("opacity", 0);
          }
        });

        const nodeExit = node
          .exit()
          .transition()
          .duration(750)
          .attr("transform", function (d) {
            return "translate(" + source.x + "," + source.y + ")";
          })
          .remove();

        nodeExit.select("circle").attr("r", 1e-6);
        nodeExit.select("text").style("fill-opacity", 1e-6);

        const link = svg.selectAll("path.link").data(links, function (d) {
          return d.id;
        });

        const linkEnter = link
          .enter()
          .insert("path", "g")
          .attr("class", "link")
          .attr("d", function (d) {
            const o = { x: source.x0, y: source.y0 };
            return diagonal(o, o);
          });

        linkEnter
          .style("fill", "none")
          .style("stroke", "#ccc")
          .style("stroke-width", "2px");

        const linkUpdate = linkEnter.merge(link);

        linkUpdate
          .transition()
          .duration(750)
          .attr("d", function (d) {
            return diagonal(d, d.parent);
          });

        const linkExit = link
          .exit()
          .transition()
          .duration(750)
          .attr("d", function (d) {
            const o = { x: source.x, y: source.y };
            return diagonal(o, o);
          })
          .remove();

        nodes.forEach(function (d) {
          d.x0 = d.x;
          d.y0 = d.y;
        });

        function diagonal(s, d) {
          const path = `M ${s.x} ${s.y}
            C ${s.x} ${(s.y + d.y) / 2},
              ${d.x} ${(s.y + d.y) / 2},
              ${d.x} ${d.y}`;

          return path;
        }

        function click(event, d) {
          if (d.children) {
            d._children = d.children;
            d.children = null;
          } else {
            d.children = d._children;
            d._children = null;
          }
          update(d);
        }

        // Mouseover event handler to show text
        function handleMouseOver(event, d) {
          d3.select(this).style("opacity", 1);
        }

        // Mouseout event handler to restore text visibility based on expanded status
        function handleMouseOut(event, d) {
          const isExpanded = d.children; // Check if the node is expanded
          d3.select(this).style("opacity", isExpanded ? 1 : 0);
        }
      }
    }
  }, [data, targetId]);

  return <div ref={chartRef} id={targetId}></div>;
}

export default TreeDiagram;