import React, { useState } from 'react';

function PreOrderPage() {
  // --- Product Data ---
  const product = {
    name: 'Origin Enclosure',
    description: 'The Origin Enclosure aims to enable a completely secure offline computing system.',
    options: {
      Basic: { price: "£200" },
      Pro: { price: "£240" },
      Full: { price: "£290" }
    },
    estimatedShippingDate: 'April 1, 2025 - December 31, 2025',
    disclaimer: "Prices are subject to change. There are specific T&Cs for this pre-order, its very important that you read them here: <a href='/terms-of-service'>Terms and Conditions</a>."
  };

  const [selectedOption, setSelectedOption] = useState(Object.keys(product.options)[0]);
  const [quantity, setQuantity] = useState('');
  const [quantityError, setQuantityError] = useState('');

  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  const handleQuantityChange = (event) => {
    const value = event.target.value;
    setQuantity(value); // Always update the state

    if (value === '') {
      setQuantityError(''); // No error if empty
    } else if (value === '1') {
      setQuantityError(''); // Clear error if it's exactly '1'
    } else {
      setQuantityError('Only a quantity of 1 is allowed.'); // Error if not '1'
    }
  };

  const parsePrice = (priceString) => {
    return parseFloat(priceString.replace(/[^0-9.]/g, ''));
  };

  const isFormValid = () => {
    // The form is valid *only* if the quantity is exactly '1'
    return quantity === '1';
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!isFormValid()) {
      return; // Prevent submission if invalid
    }

    const credits = product.options[selectedOption]?.price;

    if (credits === undefined) {
      console.error("Selected option does not have credits defined:", selectedOption);
      return;
    }
    const checkoutUrl = `/checkout?credits=${credits}&quantity=${quantity}`;
    window.location.href = checkoutUrl;
  };

  return (
    <div className="pre-order-form" style={{ marginTop: "30px", textAlign: "center" }}>
      <h2>Pre-Order: {product.name}</h2>
      <p>{product.description}</p>
      <p>Estimated Shipping: {product.estimatedShippingDate}</p>

      <form onSubmit={handleSubmit} style={{ textAlign: "center" }}>
        <div className="options-group">
          {Object.keys(product.options).map((option) => (
            <button
              type="button"
              key={option}
              className={`option-button ${selectedOption === option ? 'selected' : ''}`}
              onClick={() => handleOptionChange(option)}
              style={{ textAlign: "center" }}
            >
              {option} - {product.options[option].price}
            </button>
            
          ))}
        </div>
        <br />
        <br />
        <div>
          <input
            className="cta-input2"
            type="text" // Use type="text" for precise validation
            id="quantity"
            value={quantity}
            onChange={handleQuantityChange}
            required
            placeholder="Enter quantity"
            // Removed min and max, as validation is handled in JS
          />
          {quantityError && <p style={{ color: 'red' }}>{quantityError}</p>}
        </div>

        <br />
        <br></br>
        <p className="product-price">
          Total Price: £{(parsePrice(product.options[selectedOption]?.price || '0') * (Number(quantity) || 1)).toFixed(2)}
        </p>
        <button type="submit" className="cta-button2">
          Pre-Order Now
        </button>

        <p className="disclaimer" dangerouslySetInnerHTML={{ __html: product.disclaimer }} />
      </form>
    </div>
  );
}


export default PreOrderPage;