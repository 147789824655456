import React from 'react';
import './LandingPage.css'; // Use the same CSS for consistency
import { Link } from 'react-router-dom';

function PricingPage() {
  return (
    <div>
                  {/* <header>
        <nav>
          <ul>
          <li><Link to="/features">Features</Link></li>
            <li><Link to="/pricing">Pricing</Link></li>
            <li><Link to="/login">Login</Link></li>
            <li><Link to="/account">Account</Link></li>
          </ul>
        </nav>
      </header> */}
      <div className="pricing-table">
  <div className="plan-header">
    <h3>100 Credits</h3>
    <p>£4.99</p>
    <Link to="/checkout?credits=100&quantity=1" className="cta-button">Purchase Credits</Link> 
  </div>
  <div className="plan-header">
    <h3>1000 Credits</h3>
    <p>£39.99</p>
    <Link to="/checkout?credits=1000&quantity=1" className="cta-button">Purchase Credits</Link> 
  </div>
  <div className="plan-header">
    <h3>Enterprise</h3>
    <p>Contact Us</p>
    {/* To do, enable this */}
    <Link to="/account" className="cta-button">Get In Touch</Link> 
  </div>

  <div className="feature-row">
    <div className="feature-name">Requests</div>
    <div className="plan-value">100,000</div>
    <div className="plan-value">1,000,000</div>
    <div className="plan-value">Unlimited</div>
  </div>

  <div className="feature-row">
    <div className="feature-name">Support</div>
    <div className="plan-value">Basic Support</div>
    <div className="plan-value">Priority Support</div>
    <div className="plan-value">Dedicated Support</div>
  </div>

  <div className="feature-row">
    <div className="feature-name">Security</div>
    <div className="plan-value">Full Security</div>
    <div className="plan-value">Full Security</div>
    <div className="plan-value">Full Security</div>
  </div>
</div> 




</div>
  );
}

export default PricingPage;
