import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css';

function Nav({ children }) {
  return (
    <div>
      <header>
        <div clasName="nav-container">
        <nav >
          <ul>
          <li><Link to="/">Home</Link></li>
            </ul>
            <ul className="right-links">
            <li><Link to="/login">Login</Link></li> {/* Use Link for navigation */}
            <li><Link to="/account">Account</Link></li>
          </ul>
        </nav>
        </div>
      </header>
      <main>{children}</main>
    </div>
  );
}

export default Nav;