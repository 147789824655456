import React from 'react';
import { Link } from 'react-router-dom';
import './LandingPage.css'; // Import your blog-specific CSS

function TermsOfService() {  // Pass in your blog post data

  return (
    <div>
      <header>
        <div class="nav-container">
        <nav >
          <ul>
          <li><Link to="/">Home</Link></li>
            </ul>
            <ul class="right-links">
            <li><Link to="/login">Login</Link></li> {/* Use Link for navigation */}
            <li><Link to="/account">Account</Link></li>
          </ul>
        </nav>
        </div>
      </header>
    <section className="tos" style={{ textAlign: 'center', marginLeft: '50px', marginRight: '50px'}}>
      <h2>Terms And Conditions</h2>
        <div>
            <p>
            By using Origin Security, you hereby agree to these terms and conditions.

            The site is our proprietary property, and all source code, databases, functionality, software, website designs, audio, video, text photographs and graphics on the site and the trademarks, service marks and logos contained therein are owned and controlled by us, and are protected by copyright and trademark laws.

            You will only register domains that you control.

            You will register with an email address that is yours.

            All purchases are non-refundable.

            You may not access or use the site for any purpose other than that for which we make the site available.

            You agree that we may access, store, process and use any information and personal data that you provide following the terms in the privacy policy.

            These conditions are governed by and interpreted following the laws of the United Kingdom.

            The Service is provided "as is" without any warranties of any kind, either express or implied, including but not limited to warranties of merchantability, fitness for a particular purpose, or non-infringement.
            In no event shall the Company be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with your use of the Service.

            <br></br>
            <br></br>
            <h3>Beta-Limitations</h3>
            <br></br>
            <br></br>
            During the beta, some or all of the following limitations will apply to the product.
            <br></br>
            <p style={{ textIndent: '50px' }}>Your site will be unreachable during the approx. 5 minutes it takes for the sign up process to complete.</p>
            <p style={{ textIndent: '50px' }}>The service does not currently examine the request-body and so any attacks contained in this will not be mitigated.</p>
            <p style={{ textIndent: '50px' }}>DDoS mitigation and bot-detection may not be active / available.</p>
            <p style={{ textIndent: '50px' }}>The product may experience downtime or interruption of service resulting in your website becoming unreachable.</p>
            <p style={{ textIndent: '50px' }}>There could be other issues with the product not mentioned here.</p>
            </p>
            <h3>Pre-Orders</h3>
            <p>If pre-ordering the product you will be contacted by email asking for your delivery address. Please note, pre-orders are subject to real-world testing of the enclosure. 
              If real world tests do not meet the anticpiated performance levels, your order will be cancelled and you will be refunded the full amount. 
              Delivery will be as soon as possible after 1.4.2025 but could be as late as the end of the year. Given the early stage of the product, you accept that there are a wide range of possibilities
              in terms of what you may recieve and that Parapluie Technologies Limited is not in any way liable if the product does not meet your expectations in any way. Similarly Parapluie Technologies Limited is not liable for any
              damages you may incur as a result of the product not meeting your expectations. Nor is Parapluie Technologies Limited liable for any issues/damages you incur through your use of the product, you use it at your own risk. By placing a pre-order, you agree to these terms.
            </p>
        </div>
    </section>
    </div>
  );
}

export default TermsOfService;