import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import D3Chart from './D3.js'; // Import the D3Chart component
import NavBar from './Nav.js';
import TreeDiagram from './Tree.js'; // Assuming TreeDiagram.js is in the same directory
import './Article.css';

function Article() {
  const id = "article1";
  const [articleContent, setArticleContent] = useState('');
  const [article, setArticle] = useState(null);

  // Chart Data (Example)
  const chartData1 = [
    { x: 1, y: 2, label: "Nosy Neighbours", title: "Threat Actors" },
    { x: 1, y: 1, label: "Your Grandma", title: "Threat Actors" },
    { x: 1, y: 4, label: "Angry Acquaintances", title: "Threat Actors" },
    { x: 1, y: 3, label: "Petty Theives", title: "Threat Actors" },
    { x: 3, y: 1, label: "Local Script Kiddies", title: "Threat Actors" },
    { x: 2.5, y: 3, label: "Stalkers", title: "Threat Actors" },
    { x: 3, y: 6, label: "Organized Crime (Local)", title: "Threat Actors" },
    { x: 4, y: 3, label: "Disgruntled Employees", title: "Threat Actors" },
    { x: 5, y: 2, label: "Hacktivists with Agenda", title: "Threat Actors" },
    { x: 7, y: 2, label: "Industrial Espionage", title: "Threat Actors" },
    { x: 5, y: 6, label: "Corrupt Law Enforcement", title: "Threat Actors" },
    { x: 8, y: 9.9, label: "Nation State Military", title: "Threat Actors" },
    { x: 7, y: 8, label: "Terrorist Organizations", title: "Threat Actors" },
    { x: 8, y: 3, label: "APT Groups", title: "Threat Actors" },
    { x: 7, y: 6, label: "Organized Crime (Intl)", title: "Threat Actors" },
    { x: 9, y: 9, label: "Intelligence Agencies", title: "Threat Actors" },
    { x: 9, y: 1, label: "GCHQ", title: "Threat Actors" },
    { x: 9, y: 2, label: "NSA", title: "Threat Actors" }
  ];
  
  const chartData2 = [
    { x: 1, y: 2, label: "Nosy Neighbours", title: "Threat Actors" },
    { x: 1, y: 1, label: "Your Grandma", title: "Threat Actors" },
    { x: 1, y: 4, label: "Angry Acquaintances", title: "Threat Actors" },
    { x: 1, y: 3, label: "Petty Theives", title: "Threat Actors" },
    { x: 3, y: 1, label: "Local Script Kiddies", title: "Threat Actors" },
    { x: 2.5, y: 3, label: "Stalkers", title: "Threat Actors" },
    { x: 3, y: 6, label: "Organized Crime (Local)", title: "Threat Actors" },
    { x: 4, y: 3, label: "Disgruntled Employees", title: "Threat Actors" },
    { x: 5, y: 2, label: "Hacktivists with Agenda", title: "Threat Actors" },
    { x: 7, y: 2, label: "Industrial Espionage", title: "Threat Actors" },
    { x: 5, y: 6, label: "Corrupt Law Enforcement", title: "Threat Actors" },
    { x: 8, y: 9.9, label: "Nation State Military", title: "Threat Actors" },
    { x: 7, y: 8, label: "Terrorist Organizations", title: "Threat Actors" },
    { x: 8, y: 3, label: "APT Groups", title: "Threat Actors" },
    { x: 7, y: 6, label: "Organized Crime (Intl)", title: "Threat Actors" },
    { x: 9, y: 9, label: "Intelligence Agencies", title: "Threat Actors" },
    { x: 9, y: 1, label: "GCHQ", title: "Threat Actors" },
    { x: 9, y: 2, label: "NSA", title: "Threat Actors" }
  ];

  // Coordinates for the rectangles (top-right corners)
  const rect1 = { x: 7.2, y: 6.3 }; // Example: Blue rectangle goes up to x=5, y=8
  const rect2 = { x: 10, y: 7 }; // Example: Green "L" (horizontal) up to x=5, y=4
  const rect3 = { x: 10, y: 6.3 }; // Example: Green "L" (vertical) up to x=9, y=8

// Updated, simplified data structure:
const treeData = {
  name: "CNC Milling Machine",
  children: [
    {
      name: "Frame",
      children: [
        {
          name: "Wooden Frame Components",
          children: [
            {
              name: "Wood Logs",
              children: [
                {
                  name: "Cut Trees",
                  children: [
                    {
                      name: "Stone Tools",
                      children: [
                        {
                          name: "Sharpen Stone",
                          children: [{ name: "Find Suitable Stones" }],
                        },
                        { name: "Harden with Fire" },
                      ],
                    },
                  ],
                },
                {
                  name: "Shape Logs",
                  children: [
                    {
                      name: "Assemble Frame",
                      children: [
                        {
                          name: "Cordage",
                          children: [
                            {
                              name: "Plant Fibers",
                              children: [
                                { name: "Collect Plants" },
                                {
                                  name: "Process Fibers",
                                  children: [
                                    { name: "Retting" },
                                    { name: "Drying" },
                                    { name: "Twisting" },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "X-Axis Assembly",
      children: [
        {
          name: "X-Axis Rails",
          children: [
            {
              name: "Metal Rails",
              children: [
                {
                  name: "Iron Ore",
                  children: [
                    {
                      name: "Mine Iron Ore",
                      children: [
                        {
                          name: "Smelt Iron",
                          children: [
                            {
                              name: "Furnace",
                              children: [
                                {
                                  name: "Clay",
                                  children: [
                                    { name: "Collect Clay" },
                                    { name: "Shape Clay into Furnace" },
                                  ],
                                },
                                {
                                  name: "Charcoal",
                                  children: [
                                    {
                                      name: "Wood",
                                      children: [
                                        {
                                          name: "Pyrolysis",
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          name: "Shape Iron into Rails",
                          children: [
                            {
                              name: "Stone Hammers",
                              children: [
                                { name: "Find Suitable Stones" },
                                { name: "Shape with Other Stones" },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "X-Axis Carriage",
          children: [
            {
              name: "Wooden Platform",
              children: [
                {
                  name: "Wood",
                  children: [{ name: "Shape Wood" }],
                },
              ],
            },
          ],
        },
        {
          name: "X-Axis Drive Mechanism",
          children: [
            {
              name: "Simple Crank and Lever System",
              children: [
                {
                  name: "Wood",
                  children: [{ name: "Shape Wood" }],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Y-Axis Assembly",
      children: [
        {
          name: "Y-Axis Rails",
          children: [
            {
              name: "Metal Rails",
              children: [
                {
                  name: "Iron Ore",
                  children: [
                    {
                      name: "Mine Iron Ore",
                      children: [
                        {
                          name: "Smelt Iron",
                          children: [
                            {
                              name: "Furnace",
                              children: [
                                {
                                  name: "Clay",
                                  children: [
                                    { name: "Collect Clay" },
                                    { name: "Shape Clay into Furnace" },
                                  ],
                                },
                                {
                                  name: "Charcoal",
                                  children: [
                                    {
                                      name: "Wood",
                                      children: [
                                        {
                                          name: "Pyrolysis",
                                        },
                                      ],
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        },
                        {
                          name: "Shape Iron into Rails",
                          children: [
                            {
                              name: "Stone Hammers",
                              children: [
                                { name: "Find Suitable Stones" },
                                { name: "Shape with Other Stones" },
                              ],
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
        {
          name: "Y-Axis Carriage",
          children: [
            {
              name: "Wooden Platform",
              children: [
                {
                  name: "Wood",
                  children: [{ name: "Shape Wood" }],
                },
              ],
            },
          ],
        },
        {
          name: "Y-Axis Drive Mechanism",
          children: [
            {
              name: "Simple Crank and Lever System",
              children: [
                {
                  name: "Wood",
                  children: [{ name: "Shape Wood" }],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Cutting Tool Assembly",
      children: [
        {
          name: "Cutting Tool",
          children: [
            {
              name: "Hardened Metal Bit",
              children: [
                {
                  name: "Iron",
                  children: [{ name: "Shape into Bit" }],
                },
              ],
            },
          ],
        },
        {
          name: "Tool Holder",
          children: [
            {
              name: "Wood",
              children: [{ name: "Shape Wood" }],
            },
          ],
        },
      ],
    },
    {
      name: "Control System",
      children: [
        {
          name: "Manual Levers and Stops",
          children: [
            {
              name: "Wood",
              children: [{ name: "Shape Wood" }],
            },
            {
              name: "Cordage",
              children: [
                {
                  name: "Plant Fibers",
                  children: [
                    { name: "Collect Plants" },
                    {
                      name: "Process Fibers",
                      children: [
                        { name: "Retting" },
                        { name: "Drying" },
                        { name: "Twisting" },
                      ],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: "Power Source",
      children: [{ name: "Manual Labor" }],
    },
  ],
};

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        const metaResponse = await fetch('/data/articles.json');
        if (!metaResponse.ok) {
          throw new Error('Failed to fetch article metadata');
        }
        console.log(metaResponse)
        const articlesData = await metaResponse.json();
        console.log(articlesData)
        const foundArticle = articlesData.find((post) => post.id === id);
        console.log(foundArticle)

        if (foundArticle) {
          const response = await fetch(foundArticle.path);
          if (response.ok) {
            const html = await response.text();
            const parser = new DOMParser();
            const doc = parser.parseFromString(html, 'text/html');
            const contentElement = doc.getElementById('article-content');
            if (contentElement) {
              const content = contentElement.innerHTML;
              setArticle({
                ...foundArticle, // Keep existing metadata
                content: content // Add the content property
              });
            } else {
              console.error("Element with ID 'article-content' not found in HTML.");
              setArticle({ ...foundArticle, content: '<p>Article content not found.</p>' });
            }
          } else {
            setArticle({ title: 'Not Found', content: '<p>Article not found.</p>', author: '', date: '' });
          }
        } else {
          setArticle({ title: 'Not Found', content: '<p>Article not found.</p>', author: '', date: '' });
        }
      } catch (error) {
        console.error('Error fetching article:', error);
        setArticle({ title: 'Error', content: '<p>Error loading article.</p>', author: '', date: '' });
      }
    };

    fetchArticle();
  }, [id]);

  if (!article) {
    return <div>Loading...</div>;
  }

  const articleContainerStyle = {
    display: 'flex',
    flexDirection: 'column', // Align items vertically
    // alignItems: 'center',    // Center items horizontally
    width: '900px',          // Set the fixed width
    maxWidth: 'calc(100% - 100px)', // Prevent container from being too wide
    margin: '0 auto',   
    marginTop: '-100px',     
    padding: '20px',
    hyphens: 'auto',         // Enable hyphenation
  };
  return (
    <div>
      <NavBar />
      <div className="article-container">
        <h1>{article.title}</h1>
        <p>By: {article.author}, Published: {article.date}</p>
        {article.image && (
          <img
            src={article.image}
            alt={article.title}
            style={{ width: '400px', maxWidth: '400px' }}
          />
        )}

<div id="article-content" style={articleContainerStyle}>
    <h1 id="article-title"></h1> <p id="article-author"></p> <p id="article-date"></p>
    <article>
    <p>This article introduces Origin Security. Origin Security is a security company that aims to address digital and physical 
      security for the individual.</p>

    <p>In this article we cover:</p>

    <ul>
      <li>Why start Origin Security?
      </li>
      <li>What is Origin Security?
      </li>
      <li>What products does Origin Security offer?</li>
    </ul>
    <h2 style={{ textAlign: 'left' }}>Why start Origin Security?</h2>
    <p>So why start Origin Security?</p>
    <p>My AI queries were being routed through my previous employer
    </p>

    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/1a_640x192.png" style={{ width: '640px', height: '192px' }} alt="Cloudflare" /></div>

    <br></br>
    <p>I was trying to develop innovative tech solutions, and did not want what I was doing to be visible to them. 
      The other concern was around cybersecurity, what if someone (from there) decided to try and gain access to my laptop?</p>
    <br></br>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/2a_640x274.png" style={{ width: '640px', height: '274px' }} alt="Cybersecurity" /></div>
    <br></br>
    <p>What would the consequences be if something was compromised? I was mostly concerned about data loss from my laptop and the location being tracked on my phone. How could I make sure my systems weren't vulnerable? In response to this I decided to start Origin Security.</p>
    <h2 style={{ textAlign: 'left' }}>What is it?</h2>

    <p>Origin Security aims to address these issues by providing a comprehensive, low-cost digital and physical security solution for the individual.</p>

    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/3_640x246.png" style={{ width: '640px', height: '103px' }} alt="What is it?" /></div>

    <p>Origin Security thinks about the effeciveness of such a solution is in terms of the level of Digital and/or Physical threat that it would protect you against. This chart shows a range of potential adversaries, ranked according to the level of digital or physical threat that they might pose. If purchasing a security solution for yourself with the current state of the art systems, you could likely achieve a protection level as defined by the blue rectangle.</p>
    <br></br>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ textAlign: 'center' }} id="chart-container-1"></div>
    </div>
    <p>Origin Security improves upon this by providing complete protection in the digital domain, and improved physical security, keeping you safe from a range of more powerful adversaries. </p>
    <br></br>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <div style={{ textAlign: 'center' }} id="chart-container-2"></div>
    </div>

    <D3Chart
        data={chartData1}
        targetId="chart-container-1"
        rect1TopRight={rect1}
      />

      <D3Chart
        data={chartData2}
        targetId="chart-container-2"
        rect1TopRight={rect1}
        rect2TopRight={rect2}
        rect3TopRight={rect3}
      />
    <br></br>
    How does Origin Security's product-line do this? As seen from the plots, Origin Security thinks about threats along two axes, Digital and Physical. For each of these Origin Security has aimed to develop a complete security model, which can be implemented using the suite of products. We have divided Digital and Physical security as follows:
    <br></br>
    <br></br>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Breakdown.png" style={{ width: '768px', height: '128px' }} alt="What is it? Model" /></div>
    <br></br>
    <h2 style={{ textAlign: 'left' }}>Digital Security</h2>
    <br></br>
    <p>Origin Security thinks about digital security under three headings; internet privacy, data-loss prevention & supply chain security.</p>
    {/* <div style={{ textAlign: 'center' }}><img src="/images/Deck/Slide12.png" style={{ width: '640px', height: '360px' }} alt="Digital Security" /></div>
    <br></br> */}
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/5_640x206.png" style={{ width: '768px', height: '248px' }} alt="Digital Security Categories" /></div>
    <br></br>
    <p>Each of these has its own set of risks which are addressed by the Origin Security product-line.</p>
    <br></br>
    <h3>Internet Privacy</h3>
    <br></br>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/6_640xx.png" style={{ width: '480px', height: '238px' }} alt="Digital Security - Internet Privacy" /></div>
    <br></br>
    The websites that you visit online are visible to a whole range of people, including your ISP,
    your VPN provider, Cloudflare and others. This obviously poses a personal security risk, 
    in the below image, the fact that you are looking at the opening times for PureGym in Wandsworth is visible to anyone 
    that can see your browsing activity. Its going to be quite easy for anyone with access to your internet traffic to figure out where you're currently living.
    It makes you more vulnerable in terms of physical security to anyone that has that information.
      <br></br>
      <br></br>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/PureGym.png" style={{ width: '480px', height: '249px' }} alt="Internet Privacy - Why is it important?" /></div>
    <br></br>
    The software you're using is visible from the medium article that you're looking at. This could open you up to a targeted supply-chain attack on the software side, resulting in a compromise of your system or loss of your sensitive data.
    <br></br>
    <br></br>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/akash.png" style={{ width: '480px', height: '202px' }} alt="Internet Privacy - Why is it important?" /></div>
    <br></br>
    What you're writing to chatGPT is visible exposing your intellectual property or customer PII.
    <br></br>
    <br></br>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/chat.png" style={{ width: '480px', height: '247px' }} alt="Internet Privacy - Why is it important?" /></div>
    <p>Origin Security has a solution that addresses the concern around Cloudflare (and competitors) by providing a privacy-focussed Web Application Firewall / reverse-proxy solution. What is a reverse-proxy? If you want to put your website behind a reverse-proxy, you can simply update your DNS A-record to the provider's ip address. 
      Your traffic will then go first to their servers, and then they'll forward it on to your website after performing whatever 
      service it is that they're providing. </p>
    <br></br>
    <figure>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/ReverseProxy.png" style={{ width: '640px', height: '266px' }} alt="WAF - How it works" /></div>
    <figcaption>A reverse-proxy intercepts and forwards your end-users internet traffic.</figcaption>
    </figure>
    <br></br>
    <p>Origin Security currently provides a Web Application Firewall and plans to expand this to bot detection and DDoS mitigation. The Web Application Firewall checks the request URI, headers and body for
      attacks like SQL-injection, Cross-Site Scripting (XSS) and remote code execution (RCE).
    </p>
      <br></br>
    <figure>
      <div style={{ textAlign: 'center' }}><img src="/images/blog_images/ProductFeatures.png" style={{ width: '640px', height: '277px' }} alt="WAF - Existing product features" /></div>
      <figcaption>Reverse-proxy product features.</figcaption>
    </figure>
    <br></br>
    <p>How does the Web Application Firewall work? When the request arrives at the Origin Security server, it's decrypted and then passed the the WAF module. If the WAF finds an attack, it blocks the request
      returning a 403 Forbidden, if not it re-encrypts the request and forwards it to your origin server (you provide the ip when you sign up). Your origin server then sends its response back to the Origin Security server,
      and we forward it on to the end-user.
    </p>
    <br></br>
    <figure>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/WhatIsAWAF.png" style={{ width: '640px', height: '272px' }} alt="WAF - what is a WAF?" /></div>
    <figcaption>How a Web-Application-Firewall works.</figcaption>
    </figure>
    <br></br>
    <p>In this example, we see a type of attack called XSS (this specific example is reflected XSS). We see first that this (insecure) site allows you to search for a topic,
      and then returns some html showing the topic that you searched for. An attacker can exploit this by sending you a link to the site where the topic is replaced with a &lt;script&gt; tag. This will then be rendered on the page
      and the malicious code will execute, potentially stealing your data or compromising your system.
    </p>
    <br></br>
    <figure>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/XSS.png" style={{ width: '640px', height: '245px' }} alt="Example Attack Type" /></div>
    <figcaption>A Reflected XSS Attack.</figcaption>
    </figure>
    <br></br>
    <h4>How We're Addressing The Privacy Issue</h4>
    <p>The problem with this is, of course, that Origin Security needs to first decrypt the data before analysing it with the WAF, giving us the opportunity to look at your personal data (we don't do this obviously). The Origin Security
    WAF exists with the sole intention of enabling the development of a privacy-preserving alternative. 
    We are focussing on two options:
    <ul>
      <li>Fully Homomorphic Encryption</li>
      <li>Cryptographic Attestation</li>
    </ul>
      With fully homomorphic encryption, the web-browser
    encrypts the request with an FHE-compatible encryption scheme (i.e CKKS), the request is then sent in the regular fashion, except this time the WAF analyses it without decrypting the data. It is then either blocked or forwarded in the original encrypted form
    to your origin server. The other obvious option is cryptographic attestation, this would work by open sourcing the code (or part of the code - enough to verify what's happening with the data), signing it, end-users can then verify that the code we're running is what
    we say it is using confidential computing hardware like intel SGX. We're still working on this, and aiming to develop a web-browser to complement the encrypted WAF solution. While that's under development it is recommended that you use TOR as a privacy-preserving browser, and you can sign up for the regular WAF which is available now on our homepage.</p>
    <br></br>
    <h3>Software Supply Chain</h3>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/12_supply.png" style={{ width: '640px', height: '242px' }} alt="Current Status" /></div>
    <br></br>
    <p>The next big threat to your digital security is the software supply chain. How do you know that the software that you're using is legitimate? Or more precisely, how do you know that its
      doing what you expect it to be doing? Why wouldn't it be? Most of the applications you use today rely on various open-source or third-party software libraries. If an attacker manages to compromise one of those libraries, the 
    application that's using it might potentially be sending your data to the attacker, or giving them access to your device.
    </p>
    <figure>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/SoftwareSupplyChain.png" style={{ width: '640px', height: '226px' }} alt="Software Supply Chain" /></div>
    <figcaption>How a supply-chain attack works.</figcaption>
    </figure>
    <br></br>
    <p>With macOS, apple provides a strong degree of assurance against such threats. Their security model starts with something called the hardware root of trust. 
      The hardware root of trust is a region of read only memory (ROM) which contains the first piece of code that runs when you turn on your mac. 
      This code contains everything needed to perform a basic initialisation of the system and verify that the code in the next stage of the boot-process is correct, once this check passes it transfers control, that code the verifies the expected signature of the next stage and so on 
      until the operating system itself has fully loaded. So unless the boot-ROM of your device has been physically 
      compromised, you can be confident that the operating system is legitimate.</p>
      <br></br>
      <figure>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/HROT.png" style={{ width: '640px', height: '478px' }} alt="The macOS Security model" /></div>
    <figcaption>macOS secure-boot process.</figcaption>
    </figure>
    <br></br>
    <p>
    Once the operating system has loaded, there are other things you're going to want to do with your Mac. There are three keys steps that you're going to need to take to ensure that the device is secure:
      <ul>
        <li>Keep your software up to date (and particularly the web-browser)</li>
        <li>Only download software from trusted sources (arguably there are no trusted sources)</li>
        <li>Run any code in non-root docker containers, this ensures that any compromised libraries you might inadvertently be using are unable to access your host system.</li>
      </ul>
    </p>
    <figure>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/UserSteps.png" style={{ width: '640px', height: '272px' }} alt="The macOS Security model" /></div>
    <figcaption>Securing your mac.</figcaption>
    </figure>
    
    <br></br>
      So if we're using our mac and taking the aforementioned steps we should be pretty safe. But what about zero-day exploits (vulnerabilities that have not yet been patched),
      what if someone gains physical access to our device? Can Apple / the Intelligence services still see what we're doing? We think about addressing this remaining risk in terms of
      data-loss prevention for both online and offline devices.
    <br></br>
    <h3>Data Loss Prevention</h3>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/DLP.png" style={{ width: '640px', height: '263px' }} alt="Digital Security - Data Loss Prevention" /></div>
    <br></br>
    {/* <div style={{ textAlign: 'center' }}><img src="/images/blog_images/5_640x206.png" style={{ width: '640px', height: '206px' }} alt="Data Loss Prevention" /></div> */}
    {/* <br></br> */}
    {/* <div style={{ textAlign: 'center' }}><img src="/images/Deck/Slide30.png" style={{ width: '640px', height: '360px' }} alt="DLP" /></div> */}
    {/* <br></br> */}
    <br></br>
      <h3>Online Devices</h3>
      <p>For online devices, even after taking the precautions mentioned above, your devices are still vulnerable to zero-day exploits and physical compromise.
        If these risks concern you, you may wish to take additional steps to secure your device. You could go completely offline, but that would limit your ability to access
        AI (particularly if you want the ability to work from a cafe) or conduct research on the internet.</p>
        <p>So what you want is the ability to have a device that has potentially already been compromised, is generally *offline*, but enable it with the ability to connect to a few
        specific resources on the internet, for example, an AWS Nitro instance, or arxiv.org etc. Given that the device could be compromised at the OS level, we need to make sure that the communication is sent to the right place, and that only the data we want to be sent is
        actually sent. Origin Security has developed a solution that allows you to use a device in a semi-online manner like this. We are not currently ready to talk about the details, but this will be an important part of the final product offering and released in the near future.</p>
      <figure>
      <div style={{ textAlign: 'center' }}><img src="/images/blog_images/OnlineDevices.png" style={{ width: '640px', height: '206px' }} alt="OnlineDevices" /></div>
      <figcaption>Online-DLP product coming soon.</figcaption>
      </figure>

      <p>Putting this aside, how about just a purely offline device?</p>

    <h3>Offline Devices</h3>
    <p>So, how do you go about getting yourself an offline device? Just doing this doesn't necessarily mean that you're offline, if your OS has been compromised, your laptop might still be sending data over the wifi.</p>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/offlinewifi.png" style={{ width: '320px', height: '29px' }} alt="Physical Supply Chain" /></div>
    <br></br>
    <p>From a laptop, you're going to want to remove the wifi adapter and for a desktop obviously remove any usb dongles or ethernet cables.</p>
    <figure>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/wifiadapter.png" style={{ width: '320px', height: '320px' }} alt="How do you know its offline?" /></div>
    <figcaption>Removing the wifi adapter from a laptop.</figcaption>
    </figure>
    <br></br>
    <p>But is that enough, after doing this are you actually sure that your device is offline? Lets assume that we have a highly capable / powerful adversary that wants our data. 
      Where is it that you bought your laptop / desktop from? Did you get it in-store or was it sent via the post (including as components)? 
      How do you know that the hardware arriving at your house is what its supposed to be?</p>
      <div style={{
      display: 'flex',
      justifyContent: 'center', // Optional: Center horizontally
      alignItems: 'center',    // Optional: Center vertically
      gap: '20px',             // Optional: Space between images
    }}>
      <figure>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/PDA.jpg" style={{ width: '320px', height: '203px' }} alt="How do you know its offline?" /></div>
    <figcaption>A Royal-Mail PDA device (scanner).</figcaption>
    </figure>
    <figure>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/NSA.jpg" style={{ width: '320px', height: '239px' }} alt="How do you know its offline?" /></div>
    <figcaption>The NSA intercepting the post.</figcaption>
    </figure>
    </div>
    <p>This image is from the Edward Snowden leaks of the NSA modifying devices whilst in transit. What if somebody has installed a mini wifi adapter at a hidden location in your system, or even built one into the other components?</p>

    <figure>
    <div style={{ textAlign: 'center' }}><img src="/images/blog_images/PlantingDevice.png" style={{ width: '240px', height: '240px' }} alt="How do you know its offline?" /></div>
    <figcaption>Intelligence officer planting a covert device.</figcaption>
    </figure>
    <p>The machine could then be sending data over the wifi whilst you think its offline.</p>
    <p>Origin Security thinks about these different ways of transmitting data from the device as "covert channels" and has identified 6 of them;
      electromagnetic, acoustic, thermal, electric, optical and magnetic.</p>
      <div style={{
      display: 'flex',
      justifyContent: 'center', // Optional: Center horizontally
      alignItems: 'center',    // Optional: Center vertically
      gap: '20px',             // Optional: Space between images
    }}>
      <figure>
      <div style={{ textAlign: 'center' }}><img src="/images/blog_images/CovertChannels.png" style={{ width: '920px', height: '434px' }} alt="How do you know its offline?" /></div>
      <figcaption>6 Covert Channels for Air-Gapped Data Exfiltration.</figcaption>
      </figure>
</div>
      <p>So what might each of these look like?</p>

      <h4>Covert channel - Electromagnetic</h4>

      <p>The most likely form of covert data exfiltration is electromagnetic, with data being sent over your existing home wifi.</p>
      <p>Generally, antennas are designed to be around half the wavelength of the transmitted field in length (3-6cm for wifi).
      A device this size could be spotted, however, smartphone chip antennas might be just a few mm on their maximum dimension and something like this could be built into an existing component. As such any potential mitigaton technique should rely on blocking the waves
      as opposed to detecting the antenna (the components themselves also emit EM radiation which could be controlled to emit a signal).</p>

        {/* <div style={{ textAlign: 'center' }}><img src="/images/blog_images/ELF3.png" style={{ width: '640px', height: '495px' }} alt="How do you know its offline?" /></div> */}

        <p>Electromagnetic fields exist at different frequencies and these waves contain both an electric and magnetic component. 
          The propagation of these waves is split into near-field and far-field regions.
          In the near-field, the ratio of E/B can be higher or lower, with either the electric or magnetic component dominating.</p>
        <div style={{
      display: 'flex',
      justifyContent: 'center', // Optional: Center horizontally
      alignItems: 'center',    // Optional: Center vertically
      gap: '20px',             // Optional: Space between images
    }}>
      {/* <figure>
        <div style={{ textAlign: 'center' }}><img src="/images/blog_images/electromagnetic_spectrum.png" style={{ width: '320px', height: '180px' }} alt="How do you know its offline?" /></div>
        <figcaption>The electromagnetic spectrum.</figcaption>
      </figure> */}
      <figure>
        <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Electromagnetism.png" style={{ width: '480px', height: '119px' }} alt="How do you know its offline?" /></div>
        <figcaption>Combined electric and magnetic fields.</figcaption>
      </figure>
      {/* <figure>
        <div style={{ textAlign: 'center' }}><img src="/images/blog_images/nearfield2.png" style={{ width: '480px', height: '174px' }} alt="How do you know its offline?" /></div>
        <figcaption>Combined electric and magnetic fields.</figcaption>
      </figure> */}
      </div>

      <p>EM waves attenuate naturally in air as the wave spreads out away from the source
        (more so at higher frequencies due to interaction with air molecules). This isn't enough to prevent data exfiltration on its own of course, 
        although at high frequencies the receiver would need to be nearer to your device. Metal shields are the primary means of containing
        electromagnetic radiation. The below diagram shows the thickness required to attenuate to 1/e of the original power level.</p>

        <figure>
          <div style={{ textAlign: 'center' }}><img src="/images/blog_images/skin_depth.png" style={{ width: '320px', height: '332px' }} alt="How do you know its offline?" /></div>
          <figcaption>Skin depth vs Frequency.</figcaption>
        </figure>

        <p>Lower frequencies are generally harder to shield against, and require thicker material. This is particularly true for the magnetic component which requires thick ferrous/mu-metal materials for shielding at low-frequencies.</p>

        <p>Waves in the range of 30-300Hz 
        are called extremely low frequency waves. These are used in submarine communication. Typically they require very large transmitting antennas (left), due to the large 
        wavelength, which would not fit inside a desktop server. However, there are ways of doing transmission at this frequency with a relatively small device, such as in this paper from 2018 demonstrating a rotating-magnet based mechanical antenna (RMBMA), capable of 
        data transmission at a range of 200m with an operating frequency of 30Hz (right).</p>
        <div style={{
      display: 'flex',
      justifyContent: 'center', // Optional: Center horizontally
      alignItems: 'center',    // Optional: Center vertically
      gap: '20px',             // Optional: Space between images
    }}>
      <figure>
        <div style={{ textAlign: 'center' }}><img src="/images/blog_images/ELF1.jpg" style={{ width: '320px', height: '256px' }} alt="How do you know its offline?" /></div>
        <figcaption>1982 aerial view of the U.S. Navy Clam Lake, Wisconsin, ELF transmitter facility, used to communicate with deeply submerged submarines.</figcaption>
        </figure>
        <figure>
        <div style={{ textAlign: 'center' }}><img src="/images/blog_images/ELF2.png" style={{ width: '464px', height: '256px' }} alt="How do you know its offline?" /></div>
        <figcaption>A Rotating-magnet Based Mechanical Antenna (RMBMA) for ELF-ULF Wireless Communication Shuhong Gong1, *, Yu Liu1, and Yi Liu2.</figcaption>
        </figure>
        </div>

        <p>EM waves are the most likely means of exfiltration from our air-gapped device. Attenuation of electric fields and plane waves is 
          relatively straightforward with metal shielding, low-frequency magnetic fields are the most problematic requiring thick ferrous/high permeability materials to attenuate. Rather than attenuating these, it makes more sense to focus on detecting them with a coil.</p>

      <h4>Covert channel - Audio</h4>

      <p>In addition to our EM worry, what about sound-based exfiltration? Audio transmitters are relatively easy to make, and could potentially also be very small.
        Somebody could have installed a tiny audio transmitter in our device and be receiving the signal somewhere nearby.
      </p>

      <figure>
      <div style={{
      display: 'flex',
      flexDirection: 'horizontal',
      justifyContent: 'center', // Optional: Center horizontally
      alignItems: 'center',    // Optional: Center vertically
      gap: '20px',             // Optional: Space between images
    }}>
      <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Audio1.png" style={{ width: '296px', height: '175px' }} alt="How do you know its offline?" /></div>
      <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Audio2.jpg" style={{ width: '306px', height: '175px' }} alt="How do you know its offline?" /></div>
</div>

<figcaption>Covert Channel 3: Data Exiltration via audio.</figcaption>
</figure>

<p>There are plenty of ways to generate an acoustic signal, beyond inserting a purpose built transmitter. The existing components like the hard-drive and fan can be used to transmit a signal.</p>

<figure>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/diskfiltration.png" style={{ width: '640px', height: '225px' }} alt="How do you know its offline?" /></div>
<figcaption>Generating an audio signal with the hard-drive.</figcaption>
</figure>

<p>The acoustic signal of a CPU fan is easily detectable from a distance of a few meters with a mobile phone. Assuming that you usually have your phone within
a few meters of your workstation, this is the most likely potential receiver vs. a device being planted inside/outside of your home.</p>

<figure>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/cpu_fan.png" style={{ width: '640px', height: '355px' }} alt="How do you know its offline?" /></div>
<figcaption>Receiving an audio signal on a nearby mobile phone.</figcaption>
</figure>

<p>Any potential DLP system should also be soundproof for this reason.</p>

      <h4>Covert channel - Electric</h4>

      <p>Data could also be extracted by modulating the power passing through the power cable. For example your CPU could be programmed to consume slightly more power to transmit a 1, 
        or slightly less to transmit a zero or something like that. Somebody can then look at the graph of your home's power-consumption vs. time and extract this signal.</p>

        <figure>
    <div style={{
      display: 'flex',
      justifyContent: 'center', // Optional: Center horizontally
      alignItems: 'center',    // Optional: Center vertically
      gap: '20px',             // Optional: Space between images
    }}>
        <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Power1.png" style={{ width: '320px', height: '350px' }} alt="How do you know its offline?" /></div>
        <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Power2.png" style={{ width: '320px', height: '238px' }} alt="How do you know its offline?" /></div>
      </div>
      <figcaption>Covert Channel 4: Data Exiltration via power modulation.</figcaption>
      </figure>

      <p>Data has been exfiltrated in this way at a rate of 1000bits/s, making it a very viable method.</p>

      <h4>Covert channel - Thermal</h4>

      <p>Similarly CPU load could be controlled, or potentially even a small heating element installed in the unit in order to modulate the temperature and transmit a signal.</p>

      <figure>
      <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Thermal.png" style={{ width: '360px', height: '303px' }} alt="How do you know its offline?" /></div>
      <figcaption>Covert Channel 5: Data Exfiltration via thermal modulation.</figcaption>
      </figure>

      <p>The rate of data transfer is more limited here, but it is still a valid means of leaking data from the system and should be protected against regardless.</p>

      <h4>Covert channel - Optical</h4>

      <p>Its possible that your hardware could also have been modified to transmit data via an LED, for example on the keyboard. Someone that's able to see your keyboard from outside
        your home with a camera would be able to pick up on this signal.</p>

      <figure>
      <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Optical.png" style={{ width: '480px', height: '298px' }} alt="How do you know its offline?" /></div>
      <figcaption>Covert Channel 6: Data Exiltration via optical signals.</figcaption>
      </figure>

      <p>Optical is another valid means of air-gapped data-exfiltration, but more easily dealt with than the others simply by keeping your workstation away from the windows.</p>

      <h4>Covert channel - Nanorobotics</h4>

      <p>What about nano-robots? Could nano-robots compromise the faraday cage? Could they break material bonds or create impurities in the metal 
        such as to allow transmission? We don't currently think that this poses a threat but it could potentially be an additional covert channel of the future.</p>

      <figure>
      <div style={{
        display: 'flex',
        justifyContent: 'center', // Optional: Center horizontally
        alignItems: 'center',    // Optional: Center vertically
        gap: '20px',             // Optional: Space between images
      }}>
        <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Nano1.png" style={{ width: '240px', height: '240px' }} alt="How do you know its offline?" /></div>
        <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Nano2.png" style={{ width: '240px', height: '240px' }} alt="How do you know its offline?" /></div>
      </div>
      <figcaption>Nanobots?.</figcaption>
      </figure>

      <h2 style={{ textAlign: 'left' }}>Origin Enclosure</h2>

      <p>Origin Security has developed its enclosure product in order to address all 6 of the aforementioned covert attacks on air-gapped systems, aiming to provide the most secure offline system in the world.</p>

      <figure>
      <div style={{ textAlign: 'center' }}><img src="/images/blog_images/Enclosure.png" style={{ width: '480px', height: '480px' }} alt="How do you know its offline?" /></div>
      <figcaption>Origin Enclosure - a secure enclosure for air-gapped systems.</figcaption>
      </figure>

      <p>How does it do this?</p>

<p>
The Origin Enclosure is designed to meet or exceed the requirements set by the NATO standard, codenamed TEMPEST. TEMPEST stands for Telecommunications Electronics Materials Protected from Emanating Spurious Transmissions. This standard is classified, but the EMI requirements from an old version have been leaked online. The Origin v1 Enclosure will meet or exceed this publicly available standard (graph shown below).
</p>

<div style={{
        display: 'flex',
        justifyContent: 'center', // Optional: Center horizontally
        alignItems: 'center',    // Optional: Center vertically
        gap: '20px',             // Optional: Space between images
      }}>
<figure>
  <div style={{ textAlign: 'center' }}><img src="/images/blog_images/29_640x280.png" style={{ width: '320px', height: '257px', marginTop: '10px' }} alt="How do you know its offline?" /></div>
<figcaption>The Tempest Standard - the data exfiltration design requirements used by NATO.</figcaption>
</figure>
{/* <figure>
  <div style={{ textAlign: 'center' }}><img src="/images/blog_images/faraday.png" style={{ width: '276px', height: '276px' }} alt="How do you know its offline?" /></div>
  <figcaption>A faraday cage.</figcaption>
  </figure> */}
  </div>

<p>The core component of the solution is to have the walls of the enclosure form a faraday cage, 
  and to place the server within. When an electromagnetic field hits a Faraday cage, the free electrons redistribute themselves within the cage's material. 
  This redistribution creates an opposing electric field that cancels out the incoming wave. 
  As a result, the electromagnetic radiation can't penetrate the cage and no data can escape.</p>

<p>The enclosure has the following features:</p>
<p>
  <ul>
    <li>Metal shielding - The walls of the enclosure are formed with carefully selected metals that exceed the tempest standards.</li>
    <li>Liquid cooling compatibility - Because of the soundproofing requirement, the enclosure needs to be liquid cooled. As such, the cooling pipe needs to reach a radiator on the exterior, the gap for the pipe itself would be large enough to allow EM leakage. And so the enclosure wall has a built-in connection point that includes a grate to ensure EM attenuation at high-frequencies whilst allowing circulation of the cooling fluid with minimal pressure drop.</li>
    <li>Shielded cables - to avoid the cables becoming antennas, the enclosure comes with cable shields to stop the cables from absorbing / transmitting EM radiation.</li>
    <li>Powerline-filter - the powerline-filter blocks high-frequency siganls moving down the power cable.</li>
    <li>Cable passthroughs - the enclosure contains cable passthroughs designed with EMC best-practice methods.</li>
    <li>Airtight seal - The enclosure is airtight and comes with an airtight hatch.</li>
    <li>Soundproofing - Extensive soundproofing to attenuate any audio signals.</li>
    <li>Cable-mounts - Cables are fitted with anti-vibration mounts.</li>
    <li>Temperature sensing - Optional temperature sensor and alerting system.</li>
    <li>Constant power - A UPS that ensures the enclosure draws a constant power.</li>
    <li>Optical sealing - The enclosure is fully sealed preventing optical emissions from within.</li>
</ul>
</p>
<p>We are currently fabricating the prototype and working on the detailed design of this and will share more details as they become available.</p>

<p>
The enclosure provides TEMPEST+ levels of attenuation but this isn't enough. While we work to develop a low-cost solution to low-frequency magnetic exfiltration, the solution is to provide a detection coil alongside the pack.
</p>

<div style={{ textAlign: 'center' }}><img src="/images/blog_images/28_640x274.png" style={{ width: '640px', height: '274px' }} alt="How do you know its offline?" /></div>
<br></br>
<br></br>
<h2 style={{ textAlign: 'left' }}>Pricing</h2>

{/* style={{marginLeft: "-165px"}} */}

<div class="pricing-table-blog">

    <div class="pricing-row">
        <div class="feature-cell product-header-cell" style={{borderBottom: 'none'}}></div>
        <div class="product-cell product-header-cell">Basic</div>
        <div class="product-cell product-header-cell">Pro</div>
        <div class="product-cell product-header-cell">Full</div>
    </div>

     {/* <div class="pricing-row">
        <div class="feature-cell product-image-cell"></div>
        <div class="product-cell product-image-cell"><img src="/images/blog_images/basic.jpg" alt="Basic" class="product-image" /></div>
        <div class="product-cell product-image-cell"><img src="/images/blog_images/pro.jpeg" alt="Pro" class="product-image" /></div>
        <div class="product-cell product-image-cell"><img src="/images/blog_images/full.png" alt="Full" class="product-image" /></div>
    </div> */}
    {/* <div class="pricing-row">
        <div class="feature-cell price-cell price-top"></div>
        <div class="product-cell price-cell price-top"></div>
        <div class="product-cell price-cell price-top"></div>
        <div class="product-cell price-cell price-top"></div>
    </div> */}
  <div class="pricing-row" style={{borderTop: "1px solid #ccc"}}>
        <div class="feature-cell">External Power-on Switch</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
    </div>
    <div class="pricing-row">
        <div class="feature-cell">Electromagnetic Shielding</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
    </div>
    <div class="pricing-row">
        <div class="feature-cell">Cable-Shielding</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
    </div>
    <div class="pricing-row">
        <div class="feature-cell">Powerline-filtering</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
    </div>
    <div class="pricing-row">
        <div class="feature-cell">Air Tight Hatch</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
    </div>
    <div class="pricing-row">
        <div class="feature-cell">Soundproofing</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
    </div>
    <div class="pricing-row">
        <div class="feature-cell">Em-Compliant Cooling</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
    </div>
    <div class="pricing-row">
        <div class="feature-cell">Temperature Monitoring</div>
        <div class="product-cell no">✗</div>
        <div class="product-cell yes">✓</div>
        <div class="product-cell yes">✓</div>
    </div>
    <div class="pricing-row">
        <div class="feature-cell">Constant Power</div>
        <div class="product-cell no">✗</div>
        <div class="product-cell no">✗</div>
        <div class="product-cell yes">✓</div>
    </div>
    <div class="pricing-row">
        <div class="feature-cell price-cell" style={{borderBottom: 'none'}}></div>
        <div class="product-cell price-cell">£200</div>
        <div class="product-cell price-cell">£240</div>
        <div class="product-cell price-cell">£290</div>
    </div>
</div>
<p>* <i>Prices are subject to change.</i></p>

{/* <div style={{ textAlign: 'center' }}><img src="/images/blog_images/30_640x284.png" style={{ width: '920px', height: '426px' }} alt="How do you know its offline?" /></div> */}

<h2 style={{ textAlign: 'left' }}>Physical Security</h2>

<p>Our Physical Security offering is more basic than the digital pack, but it is designed to be enough to protect you against the average threat actor. It won't protect you from a missile.</p>
<p>We think about physical security in terms of proximity threats, aerial threats and home safety/supply chain.</p>

<div style={{ textAlign: 'center' }}><img src="/images/blog_images/PhysicalSecurity.png" style={{ width: '640px', height: '161px' }} alt="How do you know its offline?" /></div>

<h3>Proximity Threats</h3>

<div style={{ textAlign: 'center' }}><img src="/images/blog_images/32_640x328.png" style={{ width: '480px', height: '246px' }} alt="How do you know its offline?" /></div>

<p>Proximity threats just refers to anything that could cause you harm in the nearby vicinity.</p>
<br></br>
<figure>
<div style={{
        display: 'flex',
        justifyContent: 'center', // Optional: Center horizontally
        alignItems: 'center',    // Optional: Center vertically
        gap: '20px',             // Optional: Space between images
      }}>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/proximity.png" style={{ width: '240px', height: '240px' }} alt="How do you know its offline?" /></div>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/proximity2.png" style={{ width: '240px', height: '240px' }} alt="How do you know its offline?" /></div>
</div>
<figcaption>Gun / Knife as proximity threat.</figcaption>
</figure>

<p>This is people with weapons like knives or guns, or hazards like explosive barrels or high-voltage powerlines.</p>

<figure>
<div style={{
        display: 'flex',
        justifyContent: 'center', // Optional: Center horizontally
        alignItems: 'center',    // Optional: Center vertically
        gap: '20px',             // Optional: Space between images
      }}>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/proximity3.png" style={{ width: '240px', height: '240px' }} alt="How do you know its offline?" /></div>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/proximity4.png" style={{ width: '206px', height: '240px' }} alt="How do you know its offline?" /></div>
</div>
<figcaption>Non-human threats in the nearby environment as proximity threats.</figcaption>
</figure>

<p>Origin Security is providing a low-cost solution to this risk, with our early-warning drone that detects threats with the on-board camera and alerts you to them.</p>
<p>The initial version of the the threat-detection drone works on a frame-by-frame basis and aims to detect the threat and alert you to it by sounding an alarm.</p>
<p>Subsequent versions will be more sophisticated with the goal of detecting the threat at the earliest possible moment and alerting you to it. The drone may also take actions like flying towards the threat.</p>

<figure>
<div style={{
        display: 'flex',
        justifyContent: 'center', // Optional: Center horizontally
        alignItems: 'center',    // Optional: Center vertically
        gap: '20px',             // Optional: Space between images
      }}>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/drone1.png" style={{ width: '320px', height: '320px' }} alt="How do you know its offline?" /></div>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/drone2.png" style={{ width: '320px', height: '320px' }} alt="How do you know its offline?" /></div>
</div>
<figcaption>Threat-detection drone.</figcaption>
</figure>
<br></br>

<h3>Aerial Threats</h3>

<div style={{ textAlign: 'center' }}><img src="/images/blog_images/35_640x339.png" style={{ width: '320px', height: '170px' }} alt="How do you know its offline?" /></div>

<p>The next component of physical security offering is aerial-threat defence. As everyone has seen from the Ukraine war drones are a threat from the air, whether they are
  FPV or dropping an explosive from a height.
</p>
<p>Origin Security is providing a personal radar system for this, using active radar to detect small drones at low cost.
  This will not be provided with the initial version of the personal security pack that is available for pre-order, but will be included at a later date upon receipt of regulatory approval.
</p>

<figure>
<div style={{
        display: 'flex',
        justifyContent: 'center', // Optional: Center horizontally
        alignItems: 'center',    // Optional: Center vertically
        gap: '20px',             // Optional: Space between images
      }}>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/radar1.png" style={{ width: '213px', height: '213px' }} alt="How do you know its offline?" /></div>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/radar2.png" style={{ width: '213px', height: '213px' }} alt="How do you know its offline?" /></div>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/radar3.png" style={{ width: '213px', height: '213px' }} alt="How do you know its offline?" /></div>
</div>
<figcaption>Personal radar system.</figcaption>
</figure>

<br></br>

<h3>Home Safety</h3>

<div style={{ textAlign: 'center' }}><img src="/images/blog_images/38_640x515.png" style={{ width: '320px', height: '258px' }} alt="How do you know its offline?" /></div>

<p>Origin Security sees the primary issue for Home Safety as how to keep yourself safe whilst asleep.</p>
<p>Other things to consider are attacks against the food / water supply, and threats from a distance.</p>
Some hypothetical security levels might be:
<ul>
  <li>L1 - Security camera + lock</li>
  <li>L2 - L1 + bottled water & only shop at trusted stores</li>
  <li>L3 - L2 + bulletproof glass</li>
  <li>L4 - L3 + saferoom</li>
</ul>
<p>We will have more to say about this at a later date, but in the meantime Origin Security is providing a security camera and interior door-lock as part of the combined product which will be introduced next.</p>

<br></br>

<h2 style={{ textAlign: 'left' }}>Personal Security Pack</h2>

<p>Origin Security is going to offer all of these products together as the Personal Security Pack (PSP). 
The PSP includes:

<figure>
<div style={{ textAlign: 'center' }}><img src="/images/blog_images/43_640x282.png" style={{ width: '640px', height: '282px' }} alt="How do you know its offline?" /></div>
<figcaption>Origin Security Personal Security pack.</figcaption>
</figure>

<ul>
  <li> Online DLP product (coming soon)</li>
  <li> Enclosure</li>
  <li> Proxity-threat drone</li>
  <li> Active radar system (coming soon)</li>
  <li> Security camera</li>
  <li> Surveillance equipment</li>
  <li> Door-lock</li>
</ul>

Pricing for the personal security pack will start at £550, and £750 including the radar. The personal security pack combines the digital and physical security offering to form
a comprehensive end-end personal security solution for the individual, keeping you safe from threats on land, in the air and in cyberspace. </p>
<p>
A critical part of the overall solution is the supply chain. The reason we developed the enclosure in the first place, is because we couldn't trust that the hardware being sent to us in the post is what 
it was supposed to be, whether it was modified at the source or in transit, potentially it could be emitting some form of 'spurious transmission'. The same thing applies to products
that you buy from Origin Security. How do you know that you're getting what you're supposed to be getting? And how do you know that it wasn't modified en-route to your location? Origin Security
has developed a comprehensive supply-chain strategy to address exactly that.
</p>

<h3>Supply Chain</h3>

<div style={{ textAlign: 'center' }}><img src="/images/blog_images/40_640x285.png" style={{ width: '320px', height: '142px' }} alt="How do you know its offline?" /></div>

<p>As we mentioned, the enclosure product is being created in part due to the possibility of the physical supply chain being compromised during the purchase of a new device, so obviously we need to make sure
  that the same thing doesn't happen to the components that make up the enclosure. The only way to gaurantee that is with four things:
  <ul>
    <li>Raw material assurance</li>
    <li>Vertical integration</li>
    <li>Verifiable manufacturing</li>
    <li>Tamper-proof delivery</li>
  </ul>
</p>

<h4>Raw Material Assurance</h4>

<p>The raw materials are effectively the root of trust for the manufacturing process. We need to make sure that these are exactly as expected.
  This process involves things like density measurements, conductivity tests, hardness tests and so on. We haven't yet reached this point at Origin Security, but
  are planning on developing a sophisticated assurance process that gaurantees the raw materials at the level of fundamental physics.
</p>

<h4>Vertical Integration</h4>

<p>Once you've verified the raw materials as the hardware-hardware-root-of-trust, you then need to develop an entirely vertically integrated manufactufing process.
  Take for example the manufacture of a basic drone, to make a drone you need:
  <ul>
    <li>Battery</li>
    <li>Motors</li>
    <li>Motor Drivers</li>
    <li>Controller</li>
    <li>Chasis</li>
    <li>Cameras</li>
    <li>Battery</li>
    <li>Speaker</li>
    <li>Tranciever / receiver module</li>
  </ul>
  We can't buy any of these, so we have to make all the components ourselves. So how for example, do you make the chasis?
  You need:
  <ul>
    <li>3D printer</li>
    <li>CNC milling machine</li>
    <li>raw materials</li>
  </ul>
  But obviously we can't buy the 3D printer or CNC milling machine, so we have to make those ourself too. So what does that look like?
</p>

<br></br>
<div style={{ display: 'flex', justifyContent: 'center' }}>
<div id="tree-diagram-container" ></div>
</div>
<TreeDiagram
    data={treeData}
    targetId="tree-diagram-container"
  />
  <div style={{marginTop: "-450px"}}>
  <p>In order to have a truly secure and reliable product you have to build all of the tooling and manufacturing equipment starting from basic raw materials.
    Origin Security will be delivering the enclosure, alongside all other products with a compeltely vertically integrated manufacturing system, starting from fully assured
    raw materials all the way up to the end product (the assurance equipment will be made ourselves aswell of course).
  </p>
  <p>The initial version of the product is unlikely to be manufactured without any external suppliers, it will take some time for the product to be manufactured in
    a completely vertically integarted manner. Origin Security plans to reach the end-goal via the following four-stage process:</p>
    <ul>
      <li>Buy most components from suppliers, manufacture some components internally</li>
      <li>Buy some components from suppliers, manufacture most components internally</li>
      <li>Manufacture all components internally, manufacture some tooling</li>
      <li>Manufacture all components internally, manufacture all tooling</li>
    </ul>

<h4>Verifiable Manufacturing</h4>

<p>Given that we have successfully vertically integrated our manufacturing process, we know ourselves that the 
final product is what we say it is, but how does anyone else?
</p>

<p>The next important step in our process is to make sure that the manfacturing process itself is verifiable by the end-user.
  This is something that we're still working on, and is going to be one of Origin Security's key innovations.
  So, for example, lets say that the manufacturing process is:
  <p>Receive raw aluminium -> Verify aluminium is aluminium -> Cut aluminium to size -> Perform CNC Machining -> Propeller Mounting Plate</p>

  It should be possible for the end-user, given the delivery of the propeller mounting plate, to verify that this is what happened.
  This is a process that will also be developed and improved upon over time, for example, an initial process might be as simple as to video the
  manufacturing process from end-end inlcuding the placement into a tamper-proof box. The final process aims to be a complete proof, and details of this will
  be shared in a subsequent blog-post.
</p>

<h4>Tamper-proof Delivery</h4>

<p>
Given that we've made it possible to verify the manufacturing process for the product that you're recieving, how do you know that it wasn't tampered with in transit?
Its common to see tamper-proof seals on packages that are delivered. 
Origin Security is aiming to design a shipping container with tamper-proof properties that cannot be bypassed. 
This will also be the subject of a subsequent blog-post.</p>
<p>This kind of thing won't be possible https://www.youtube.com/watch?v=0YB4ygYAxDk</p>

<h2 style={{ textAlign: 'left' }}>What's next</h2>

<p>Look out for the launch of our kickstarter campaigns, this will be the best place to pre-order the product.
Pre-orders should be available from late February and the product is expected to ship from 01.04.2025.
</p>
<br></br>
<br></br>
<br></br>
<br></br>
<br></br>

</div>

</article>
</div>
  
      </div>
      <style jsx>{`
        .tooltip {
          position: absolute;
          text-align: center;
          padding: 8px;
          font: 12px sans-serif;
          background: rgba(255, 255, 255, 0.9); // Slightly transparent white
          border: 1px solid #ccc;
          border-radius: 4px;
          pointer-events: none;
          opacity: 0;
          transition: opacity 0.2s ease-in-out; /* Smooth transitions */
          box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2); /* Add a subtle shadow */
        }
      `}</style>
    </div>
  );
}

export default Article;