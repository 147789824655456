import React, { useEffect, useRef } from 'react';
import * as d3 from 'd3';

function D3Chart({ data, targetId, rect1TopRight, rect2TopRight, rect3TopRight }) {
  const chartRef = useRef(null);

  useEffect(() => {
    if (data && targetId) {
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.innerHTML = '';

        const margin = { top: 40, right: 70, bottom: 60, left: 70 };
        const width = 950 - margin.left - margin.right;
        const height = 500 - margin.top - margin.bottom;

        // Define colors with transparency (alpha = 0.7 for 70% opacity)
        const customBlue = 'rgba(182, 203, 214, 0.7)';
        const customGreen = 'rgba(188, 228, 199, 0.7)';

        const svg = d3.select(targetElement)
          .append('svg')
          .attr('width', width + margin.left + margin.right)
          .attr('height', height + margin.top + margin.bottom)
          .append('g')
          .attr('transform', `translate(${margin.left}, ${margin.top})`);

        const maxY = 10;

        const x = d3.scaleLinear()
          .domain([0, 10])
          .range([0, width]);

        const y = d3.scaleLinear()
          .domain([0, maxY])
          .range([height, 0]);

        svg.append("g")
          .attr("class", "grid")
          .attr("transform", `translate(0, ${height})`)
          .call(d3.axisBottom(x)
            .tickSize(-height)
            .tickFormat("")
          );

        svg.append("g")
          .attr("class", "grid")
          .call(d3.axisLeft(y)
            .tickSize(-width)
            .tickFormat("")
          );

        svg.append('g')
          .attr('transform', `translate(0, ${height})`)
          .call(d3.axisBottom(x))
          .append('text')
          .attr('fill', '#000')
          .attr('x', width / 2)
          .attr('y', margin.bottom - 10)
          .attr('text-anchor', 'middle')
          .style("font-size", "16px")
          .text('Digital Threat');

        svg.append('g')
          .call(d3.axisLeft(y))
          .append('text')
          .attr('fill', '#000')
          .attr('transform', 'rotate(-90)')
          .attr('y', 0 - margin.left)
          .attr('x', 0 - (height / 2))
          .attr('dy', '1em')
          .attr('text-anchor', 'middle')
          .style("font-size", "16px")
          .text('Physical Threat');

        // --- Drawing the Rectangles Correctly ---

        // Blue rectangle (no changes)
        if (rect1TopRight) {
          svg.append('rect')
            .attr('x', x(0))
            .attr('y', y(rect1TopRight.y))
            .attr('width', x(rect1TopRight.x))
            .attr('height', height - y(rect1TopRight.y))
            .attr('fill', customBlue)
            ;
        }

        // Green "L-shape" (combine rect2 and rect3 logic)
        if (rect2TopRight && rect3TopRight) {
          // Horizontal part of the "L"
          svg.append('rect')
            .attr('x', x(0))
            .attr('y', y(rect2TopRight.y))
            .attr('width', x(rect3TopRight.x)) // Extend to the end of rect3
            .attr('height', y(rect1TopRight.y) - y(rect2TopRight.y)) // Only up to blue rect
            .attr('fill', customGreen)
            ;

          // Vertical part of the "L" 
          svg.append('rect')
            .attr('x', x(rect1TopRight.x)) // Start from the right of the blue rect
            .attr('y', y(rect3TopRight.y))
            .attr('width', x(rect3TopRight.x) - x(rect1TopRight.x))
            .attr('height', height - y(rect3TopRight.y)) // Extend to the bottom
            .attr('fill', customGreen)
            ;
        }

        // Scatter plot points
        svg.selectAll('circle')
          .data(data)
          .enter()
          .append('circle')
          .attr('cx', d => x(d.x))
          .attr('cy', d => y(d.y))
          .attr('r', 5)
          .attr('fill', '#69b3a2')
          .on("mouseover", handleMouseOver)
          .on("mouseout", handleMouseOut);

        // Labels above points
        svg.selectAll('.label')
          .data(data)
          .enter()
          .append('text')
          .attr('class', 'label')
          .style("font-size", "14px")
          .attr('x', d => x(d.x))
          .attr('y', d => y(d.y) - 10)
          .attr('text-anchor', 'middle')
          .text(d => d.label);

        // Add title
        svg.append("text")
          .attr("x", (width / 2))
          .attr("y", 0 - (margin.top / 2))
          .attr("text-anchor", "middle")
          .style("font-size", "16px")
          .text(data[0].title);

        const tooltip = d3.select("body").append("div")
          .attr("class", "tooltip")
          .style("opacity", 0);

        function handleMouseOver(event, d) {
          tooltip.transition()
            .duration(200)
            .style("opacity", .9);
          tooltip.html(d.label + "<br>x: " + d.x + ", y: " + d.y)
            .style("left", (event.pageX) + "px")
            .style("top", (event.pageY - 28) + "px");
        }

        function handleMouseOut(event, d) {
          tooltip.transition()
            .duration(500)
            .style("opacity", 0);
        }

        svg.selectAll(".grid line")
          .style("stroke", "lightgray")
          .style("stroke-opacity", "1.0")
          .style("shape-rendering", "crispEdges");

        // --- Add Legend (Conditional) ---
        const legend = svg.append('g')
          .attr('class', 'legend')
          .attr('transform', `translate(${margin.left}, ${margin.top})`);

        // Legend Data (Conditional)
        const legendData = [
          { color: customBlue, label: 'DIY Security' },
        ];

        // Add second legend item ONLY if both green rectangles are present
        if (rect2TopRight && rect3TopRight) {
          legendData.push({ color: customGreen, label: 'Origin Security' });
        }

        const legendItems = legend.selectAll('.legend-item')
          .data(legendData)
          .enter()
          .append('g')
          .attr('class', 'legend-item')
          .attr('transform', (d, i) => `translate(0, ${i * 20})`); // Adjust spacing if needed

        legendItems.append('rect')
          .attr('width', 15)
          .attr('height', 15)
          .attr('fill', d => d.color);

        legendItems.append('text')
          .attr('x', 20)
          .attr('y', 10)
          .text(d => d.label)
          .style('font-size', '14px');
      }
    }
  }, [data, targetId, rect1TopRight, rect2TopRight, rect3TopRight]);

  return <div ref={chartRef} id={targetId} style={{marginLeft: "-50px"}}></div>;
}

export default D3Chart;